/* External dependencies */
import { Col, Div, Input, Label, Row, Text, Textarea } from 'atomize';
import React from 'react';
import { FormattedMessage } from 'react-intl';

/* Local dependencies */
import { UIException } from '../../redux/exceptions';
import {
  defaultInputStyle,
  errorMessageStyle,
  labelStyle,
} from '../form/FormStyles';
import '../form/formstyle.scss';

interface FormFieldProps {
  autoFocus?: boolean;
  autoComplete?: string;
  bg?: string;
  borderColor?: string;
  children?: React.ReactNode;
  colSize?: string;
  colSizeLabel?: string;
  cursor?: string;
  disabled?: boolean;
  error?: UIException;
  errorMessageColor?: string;
  fieldDescription?: string;
  fieldDescriptionId?: string;
  focusBg?: string;
  inputName: string;
  inputStyle?: Object;
  inputType: string;
  inputValue: React.ReactNode;
  label?: React.ReactNode;
  labelSize?: string;
  minHeight?: string;
  onChange?: Function;
  onClick?: () => void;
  placeholder?: React.ReactChild;
  whiteSpace?: string;
  required?: boolean;
  reference?: any;
  suffixIcon?: React.ReactChild;
  tabIndex?: string;
  divStyles?: Object;
  onInput?: (x) => void;
  prefix?: any;
}

export default function FormField({
  autoFocus,
  autoComplete,
  bg = 'input_bg', //default bg color
  borderColor,
  children,
  cursor = '',
  colSize,
  colSizeLabel,
  disabled,
  error,
  errorMessageColor,
  fieldDescription,
  fieldDescriptionId,
  focusBg,
  label,
  labelSize = '',
  minHeight,
  inputName,
  inputStyle = defaultInputStyle,
  inputType,
  inputValue,
  onChange,
  onClick,
  placeholder,
  whiteSpace = '',
  required,
  reference,
  suffixIcon,
  tabIndex,
  divStyles,
  onInput,
  prefix,
}: FormFieldProps) {
  return (
    <>
      {label ? (
        <Col size={{ xs: '12', sm: colSizeLabel || '5' }}>
          <Row>
            <Col d="flex" justify="space-between">
              <Label
                {...labelStyle}
                textSize={labelSize || labelStyle.textSize}
                style={{ whiteSpace: whiteSpace }}
              >
                {label}
              </Label>
            </Col>
          </Row>
        </Col>
      ) : null}
      <Col size={{ xs: '12', sm: colSize || '' }} w="100%">
        <Div
          m={{ b: '1rem' }}
          pos="relative"
          onClick={onClick}
          ref={reference}
          {...divStyles}
        >
          {inputType === 'textarea' ? (
            <Textarea
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              bg={bg}
              style={{ paddingTop: '6px' }}
              disabled={disabled}
              id={inputName}
              type={inputType}
              name={inputName}
              placeholder={placeholder}
              value={
                typeof inputValue === 'number' ? inputValue : inputValue || ''
              }
              onChange={onChange}
              required={required}
              tabIndex={tabIndex}
              focusBg={focusBg}
              {...inputStyle}
              suffix={suffixIcon}
              onInput={onInput}
              prefix={prefix}
              cursor={cursor}
            />
          ) : (
            <Input
              cursor={cursor}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              bg={bg}
              minH={minHeight}
              disabled={disabled}
              id={inputName}
              type={inputType}
              name={inputName}
              placeholder={placeholder}
              value={
                typeof inputValue === 'number' ? inputValue : inputValue || ''
              }
              onChange={onChange}
              required={required}
              tabIndex={tabIndex}
              focusBg={focusBg}
              {...inputStyle}
              suffix={suffixIcon}
              borderColor={borderColor}
              prefix={prefix}
            />
          )}
          {children}
          <Text
            m={errorMessageStyle.m}
            textAlign="left"
            textSize="caption"
            textColor="backgroundTextColor"
            id={fieldDescriptionId}
          >
            {fieldDescription}
          </Text>
          {error && (
            <Text
              textSize="caption"
              {...errorMessageStyle}
              textColor={errorMessageColor || errorMessageStyle.textColor}
            >
              <FormattedMessage id={error.code} />
            </Text>
          )}
        </Div>
      </Col>
    </>
  );
}

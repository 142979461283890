/* External dependencies */
import * as React from 'react';

/* Local dependencies */
import { IconProps } from './types';

interface PasswordEye extends IconProps {
  isEmpty?: boolean;
  showPassword: boolean;
}

export default function PasswordEye({ isEmpty, showPassword }: PasswordEye) {
  const fillColor = isEmpty ? '#8F9BB3' : '#3366FF';

  return (
    <>
      {showPassword ? (
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 8.50002C9.17305 8.50002 8.50005 7.82702 8.50005 7.00002C8.50005 6.17302 9.17305 5.50002 10 5.50002C10.827 5.50002 11.5 6.17302 11.5 7.00002C11.5 7.82702 10.827 8.50002 10 8.50002ZM10 3.50002C8.07005 3.50002 6.50005 5.07002 6.50005 7.00002C6.50005 8.93002 8.07005 10.5 10 10.5C11.93 10.5 13.5 8.93002 13.5 7.00002C13.5 5.07002 11.93 3.50002 10 3.50002ZM10.2197 11.9976C5.91375 12.0976 3.10475 8.41462 2.17275 6.99562C3.19875 5.39062 5.78275 2.10462 9.78075 2.00262C14.0697 1.89362 16.8948 5.58562 17.8267 7.00462C16.8018 8.60962 14.2168 11.8956 10.2197 11.9976ZM19.8678 6.50262C19.2297 5.39062 15.7057 -0.183379 9.72975 0.00362058C4.20175 0.143621 0.98675 5.01362 0.13275 6.50262C-0.04425 6.81062 -0.04425 7.18962 0.13275 7.49762C0.76175 8.59462 4.16175 13.9996 10.0247 13.9996C10.1067 13.9996 10.1888 13.9986 10.2708 13.9966C15.7977 13.8556 19.0138 8.98662 19.8678 7.49762C20.0438 7.18962 20.0438 6.81062 19.8678 6.50262Z"
            fill={fillColor}
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 10.5003C9.17305 10.5003 8.50005 9.82725 8.50005 9.00025C8.50005 8.98717 8.50306 8.97437 8.50607 8.96155L8.50608 8.96154C8.50882 8.94986 8.51157 8.93817 8.51205 8.92625L10.074 10.4883C10.0621 10.4887 10.0504 10.4915 10.0388 10.4942C10.0259 10.4972 10.0131 10.5003 10 10.5003ZM2.70705 0.29325C2.31605 -0.09775 1.68405 -0.09775 1.29305 0.29325C0.90205 0.68425 0.90205 1.31625 1.29305 1.70725L6.92305 7.33725C6.64705 7.84625 6.50005 8.41125 6.50005 9.00025C6.50005 10.9303 8.07005 12.5002 10 12.5002C10.5891 12.5002 11.154 12.3532 11.663 12.0772L17.293 17.7073C17.488 17.9023 17.744 18.0002 18 18.0002C18.2561 18.0002 18.5121 17.9023 18.7071 17.7073C19.0981 17.3162 19.0981 16.6842 18.7071 16.2932L2.70705 0.29325ZM10.2198 13.9979C5.91475 14.0978 3.10475 10.4149 2.17275 8.99585C2.62975 8.28185 3.39575 7.23585 4.45575 6.28485L3.04475 4.87285C1.52275 6.26185 0.54675 7.77985 0.13275 8.50285C-0.04425 8.81085 -0.04425 9.18985 0.13275 9.49785C0.76175 10.5948 4.16175 15.9998 10.0247 15.9998C10.1067 15.9998 10.1888 15.9988 10.2708 15.9968C11.4548 15.9668 12.5268 15.7109 13.4978 15.3269L11.9178 13.7468C11.3828 13.8888 10.8198 13.9829 10.2198 13.9979ZM9.72975 2.00365C15.7048 1.81665 19.2297 7.39065 19.8678 8.50265C20.0438 8.81065 20.0438 9.18965 19.8678 9.49765C19.4527 10.2206 18.4767 11.7387 16.9548 13.1277L15.5437 11.7157C16.6038 10.7647 17.3708 9.71865 17.8267 9.00465C16.8948 7.58565 14.0717 3.89465 9.78075 4.00265C9.18075 4.01765 8.61775 4.11165 8.08175 4.25365L6.50175 2.67365C7.47375 2.28965 8.54475 2.03365 9.72975 2.00365Z"
            fill={fillColor}
          />
        </svg>
      )}
    </>
  );
}

/* External dependencies */
import { Div, ThemeProvider } from 'atomize';
import React from 'react';

/* Local dependencies */
import BTSLogo from '../../svg/BTSLogo';
import './animated-logo.scss';

interface IAnimatedLogo {
  animate: boolean;
}

export default class AnimatedLogo extends React.Component<{}, IAnimatedLogo> {
  constructor(props) {
    super(props);

    this.state = {
      animate: false,
    };

    this.toggleState = this.toggleState.bind(this);
  }

  componentDidMount() {
    setTimeout(this.toggleState, 1000);
  }

  toggleState = () => this.setState({ animate: true });

  render() {
    const { animate } = this.state;
    const icon_width = 484.6;
    const icon_height = 120;

    return (
      <Div
        d="flex"
        justify="center"
        align="center"
        className="animatedLogo"
        transition="transform 0.4s linear"
      >
        <BTSLogo fill="#3366FF" width={icon_width} height={icon_height} />
      </Div>
    );
  }
}

/* External dependencies */
import { Anchor, Button, Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FormEvent } from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import PasswordEye from '../../assets/svg/passwordEyeIcons';
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import { forgotPassword, login } from '../login/redux/actions';
import { LoginState } from '../login/redux/reducer';
import FormField from '../form/FormField';
import { defaultInputStyle, formStyle, wrapStyle } from '../form/FormStyles';

const phoneCodeKG = '+996';

export default function LoginForm() {
  const [username, setUsername] = React.useState({ value: '', number: '' });
  const [password, setPassword] = React.useState('');
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const { error, loading, newPasswordRequired } = ReactRedux.useSelector(
    (store: RootState): LoginState => store.login,
  );

  const validateUsername = (username) => {
    let clean = username.trim().replace(/[^\d]/g, '').substring(0, 9);

    let formattedPhone = '';

    let len = clean.length;

    switch (true) {
      case len < 1:
        formattedPhone = '';
        break;

      case len <= 3:
        formattedPhone = `(${clean}`;
        break;

      case len > 3 && len < 7:
        formattedPhone = `(${clean.substring(0, 3)}) ${clean.substring(3, 6)}`;
        break;

      default:
        formattedPhone = `(${clean.substring(0, 3)}) ${clean.substring(
          3,
          6,
        )}-${clean.substring(6, 9)}`;
    }

    setUsername({ value: formattedPhone, number: clean });
  };

  const validatePassword = (password) => {
    setPassword(password.trim());
  };

  if (newPasswordRequired) {
    navigate('/login/change-password');

    return null;
  }

  const dispatch = ReactRedux.useDispatch();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    const correctUsername = (phoneCodeKG + username.number).trim();

    dispatch(login(correctUsername, password));

    e.preventDefault();

    return false;
  };

  const onForgotPassword = () => dispatch(forgotPassword(username.number));

  const isInvalid = !username.number || !password;

  const togglePassword = (e) => {
    e.preventDefault();

    setIsPasswordShown((prev) => !prev);
  };

  const phoneCodeStyles = {
    ...defaultInputStyle,
    p: { l: '3.4rem', r: '1.3rem' },
  };

  return (
    <Div {...wrapStyle} style={{ width: 'inherit' }}>
      <form onSubmit={onSubmit} style={formStyle}>
        <Row m="0">
          <Col
            border={{ b: '1px solid' }}
            textAlign="left"
            borderColor="basic_border_color"
          >
            <Text tag="h1" textSize="18px" textWeight="bold" m="1rem">
              {formatMessage('loginSignIn')}
            </Text>
          </Col>
        </Row>
        <Row p="1rem" m="0">
          <FormField
            autoFocus={true}
            disabled={loading}
            label={formatMessage('phone')}
            inputValue={username.value}
            inputType="tel"
            inputName="username"
            onChange={(e) => validateUsername(e.target.value)}
            placeholder={formatMessage('phonePlaceholder')}
            required={true}
            tabIndex="1"
            inputStyle={phoneCodeStyles}
            prefix={
              <Text
                textColor="table_cell_text_color"
                textSize="body"
                textWeight="600"
                pos="absolute"
                top="51.5%"
                left="1rem"
                transform="translateY(-50%)"
              >
                {phoneCodeKG}
              </Text>
            }
          />
          <FormField
            disabled={loading}
            label={formatMessage('password')}
            inputValue={password}
            inputType={isPasswordShown ? 'text' : 'password'}
            inputName="password"
            onChange={(e) => validatePassword(e.target.value)}
            placeholder={formatMessage('passwordPlaceholder')}
            required={true}
            tabIndex="2"
            suffixIcon={
              <Button
                pos="absolute"
                onClick={togglePassword}
                bg="transparent"
                w="3rem"
                top="0"
                right="0"
                rounded={{ r: 'md' }}
                type="button"
              >
                <PasswordEye
                  showPassword={isPasswordShown}
                  isEmpty={password.length < 1}
                />
              </Button>
            }
          />
          {error && (
            <Col>
              <Text textColor="red">
                {<FormattedMessage id={error.code || error.message} />}
              </Text>
            </Col>
          )}
        </Row>
        <Row
          m="0"
          border={{ t: '1px solid' }}
          borderColor="basic_border_color"
          p="1rem"
        >
          <Col d="flex" justify="flex-start" align="center">
            <Anchor onClick={onForgotPassword}>
              <Text>{formatMessage('forgotPassword')}</Text>
            </Anchor>
          </Col>
          <Col d="flex" justify="flex-end">
            <PrimaryButton
              disabled={isInvalid || loading}
              loading={loading}
              onClick={onSubmit}
            >
              {formatMessage('loginSubmit')}
            </PrimaryButton>
          </Col>
        </Row>
      </form>
    </Div>
  );
}
